
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class FloatingButton extends Vue {
  @Prop() disabled?: boolean;
  @Prop() icon?: string;

  submit(): void {
    this.$emit('submit', true);
  }
  get fontAwesomeIcon() {
    return this.icon ? this.icon : 'save';
  }
}
