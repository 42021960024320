
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueEditor } from "vue2-editor";
import Quill from 'quill';
import 'vue2-editor/dist/vue2-editor.css';
import { NOTICE_TYPE } from '@/enum/enums';

@Component({
  components: {
    VueEditor,
  }
})
export default class ContentEditor extends Vue {
  @Prop() content?: string;
  @Prop() menu? : boolean;
  @Prop() editable?: boolean;
  @Prop() pending!: boolean;
  private currentContent: string | null = null;
  private unwatch: ReturnType<typeof Function> | null = null;

  created() {
    if (this.content) this.currentContent = this.content;
  }

  mounted() {
    this.unwatch = this.$watch('content', this.setInitialContent);
    this.setHeight();
  }

  private setHeight() {
    const ref = this.$refs.editor;
    if (ref) {
      const { $el } = ref as Vue;
      if ($el) {
        const height = $el.clientHeight;
        const toolbar = $el.querySelector('.ql-toolbar') as HTMLDivElement | null;
        const container = $el.querySelector('#quill-container') as HTMLDivElement | null;
        if (toolbar && container) {
          const scrollHeight = container.scrollHeight;
          const toolBarHeight = toolbar.clientHeight;
          console.log(height);
          container.style.height = `${height - toolBarHeight}px`;
        }
      }
    }
  }

  setInitialContent(content: string) {
    this.currentContent = content;
    if (this.unwatch) this.unwatch();
  }

  contentInput(content: string) {
    this.$emit('contentInput', content);
  }

  get wrapper(): HTMLDivElement | undefined {
    const ref = this.$refs['editor-wrapper'];
    return ref ? ref as HTMLDivElement : undefined;
  }

  get editor() {
    const ref = this.$refs.editor;
    return ref ? ref as Vue : undefined;
  }

  get customToolbar () {
    return [
      ["bold", "italic", "underline"],
      ["code-block"]
    ]
  }
}
