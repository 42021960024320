
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { NOTICE_TYPE } from '@/enum/enums';
import { FileInfo, Notice, SocketMessage } from '@/interface/interface';
import ContentEditor from '@/components/editor.vue';
import CommentComponent from '@/pages/my-page/board/comment.vue';
import BorderHeader from '@/pages/my-page/board/board-header.vue';
import BoardMixin from '@/pages/my-page/board/board-mixin.vue';
import Uploader from '@/components/file-uploader.vue';
import FloatingButton from '@/components/floating-btn.vue';

@Component({
  components: {
    ContentEditor,
    CommentComponent,
    BorderHeader,
    BoardMixin,
    Uploader,
    SubmitButton: FloatingButton,
  }
})
export default class BoardWrite extends Mixins(Vue, BoardMixin) {
  private noticeTypes: { text: string; value: NOTICE_TYPE }[] = [];
  private groups: string[] = [];
  private recUserId: string[] = [];
  private _id = '';
  private doc: Notice | null = null;
  private userForm: string | null = null;
  private userSearchList: { userId: string, userName: string, userGroup: string }[] = [];
  private confirmModal = false;
  async created(): Promise<void> {
    const { _id } = this.$route.params;
    this._id = _id;
    await this.load();
  }

  async managerSearch(): Promise<void> {
    if (!this.userForm) return;
    const { data, result, message } = await this.axios({
      url: `/user/search-manager`,
      params: {
        manager: this.userForm
      }
    });
    if (result) {
      this.userSearchList = [];
      const { managers: userSearchList } = data as { managers: { userId: string, userName: string, userGroup: string }[] };
      if (message) this.successMessage(message.join(''));
      this.userSearchList = userSearchList;
    }
  }

  private deSelectedUser(idx: number) {
    this.recUserId.splice(idx, 1);
  }

  async load(): Promise<void> {
    this.loading = true;
    this.doc = await this.getContent(this._id);
    if (this.doc) {
      this.noticeTypes = await this.getTypes(this.doc.new ? this.doc._id : this._id);
      if (this.noticeTypes.length === 1) {
        this.doc.type = this.noticeTypes[0].value;
        this.doc.typeLabel = this.noticeTypes[0].text;
      }
      this.groups = await this.getGroups();
      if (this.groups.length === 1) this.doc.targetGroup = this.groups;
      this.recUserId = this.doc.sendUserId;
    }
    // await new Promise((resolve) => setTimeout(resolve, 500));
    this.loading = false;
  }

  contentInput(value: string): void {
    if (this.doc) {
      this.doc.content = value;
    }
  }

  private typeChange() {
    console.log(this.doc?.type)
  }

  async save() {
    if (this.doc === null) return;
    if (!this.confirmModal) {
      return this.confirmModal = true;
    }
    this.proc(true);
    // await new Promise((resolve) => setTimeout(resolve, 1500));
    this.confirmModal = false;
    const params = {
      title: this.doc.title,
      content: this.doc.content,
      type: this.doc.type,
      recUserId: this.recUserId,
      commentActive: this.doc.commentActive,
      targetGroup: this.doc.targetGroup,
      uploadedFiles: this.doc.uploadedFiles,
    }
    const url = `/notice/${this.doc.new ? this.doc._id : this._id}`;
    const { data, message, result } = await this.axios({
      url,
      method: this.doc.new ? 'POST' : 'PATCH',
      data: params,
    });

    if (result) {
      const { doc } = data as { doc: Notice, owner: boolean, replyAvailable: boolean };
      if (message) {
        this.successMessage((message as string[]).join('\r\n'));
      }
      this.doc = null;
      this.proc(false);
      await this.$router.push({ path: '/my-page/board' });
    } else {
      this.validationMessages(message)
    }
    this.proc(false);
  }

  uploaded(files: FileInfo[]) {
    if (this.doc) {
      this.doc.uploadedFiles.push(...files);
    }
  }

  removeFile(id: string): void {
    if (this.doc) {
      const index = this.doc.uploadedFiles.findIndex(file => file.id === id);
      if (index > -1) this.doc.uploadedFiles.splice(index, 1);
    }
  }

  get editable() {
    if (this._id === 'new') return true;
    if (!this.doc) return false;
    if (!this.owner) return false;
    if (this.doc.type === NOTICE_TYPE.ANSWER && this.doc.answered) return false;
    return true;
  }

  public get owner(): boolean {
    return this.doc ? this.doc.regUserId === this.userId : false;
  }

  get readCount() {
    return new Intl.NumberFormat().format(this.doc ? this.doc.readCount : 0);
  }

  get isNew(){
    return this._id === 'new';
  }

  get availableMangerSearch() {
    return this.doc ? [NOTICE_TYPE.PANEL_INQUIRY, NOTICE_TYPE.SPECIFIC].includes(this.doc.type) : false;
  }

  get contentLength(): string {
    return this.doc ? new Intl.NumberFormat().format(this.doc.content.length) : '0';
  }
}
